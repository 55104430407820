












import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { NavbarSimple } from '../../../../front/shared/organisms/Navbar'
import { toImageProps } from '../../../../front/shared/support'
import { AuthMixin } from '../../../../front/auth/shared'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component({
  name: 'SimpleNavbar',
  components: { NavbarSimple }
})
export class SimpleNavbar extends AuthMixin {
  @Prop({ type: Object, required: true })
  public readonly data!: AnyObject

  /**
   * Determines brand image (logo) of the service
   */
  public get brandImage (): ImageProps | undefined {
    return typeof this.data.content.brandImage === 'undefined' || !this.data.content.brandImage
      ? undefined
      : toImageProps(this.data.content.brandImage)
  }

  public get secondaryBrandImage (): ImageProps | undefined {
    return typeof this.data.content.secondaryBrandImage === 'undefined' || !this.data.content.secondaryBrandImage
      ? undefined
      : toImageProps(this.data.content.secondaryBrandImage)
  }

  /**
   * @override
   */
  public get shouldRender (): boolean {
    return this.data && this.data.content && Object.keys(this.data.content).length > 0
  }
}

export default SimpleNavbar
